<template>
  <div>
    <v-container
      style="padding: 0px"
      fluid
      :class="{ desktop_view: $vuetify.breakpoint.mdAndUp }"
    >
      <!-- SHOW DOCUMENT -->
      <att
        v-if="show_attachment"
        :base64="this.get_document_value('base64')"
        :ext="this.get_document_value('extension')"
        :file_name="this.get_document_value('file_name')"
      />
      <!-- SHOW LIST ALL DOCUMENT -->
      <documents v-else-if="show_documents" />
      <!-- HOME PAGE -->
      <v-container v-else>
        <!-- LOGOTYPE v-if="rectangle_icon || square_icon" get_image('logo_image_base64')-->
        <v-row dense>
          <v-img
            alt="Mäklarföretagets logotyp"
            :src="this.get_logo()"
            :max-height="header_icon_height"
            contain
            position="center top"
          >
          </v-img>
        </v-row>
        <!--    STREET ADDRESS Erik Olsson -->
        <v-row style="justify-content: center" v-if="this.org_key == '2282'">
          <div style="color: #000000">
            <b>{{ get_value('boostad_get_code.estate', 'streetAddress') }}</b>
          </div>
        </v-row>
        <!-- ESTATE PICTURE   class="img_border"     -->
        <v-row style="padding-top: 10px">
          <v-img
            alt="Bild på objektet"
            :aspect-ratio="16 / 9"
            width="100%"
            :src="this.get_value('boostad_get_code.estate', 'url_img')"
          >
            <!--    STREET ADDRESS -->
            <div v-if="this.org_key != '2282'" id="parent">
              <div style="text-align: center; color: #121212" id="child">
                {{ get_value('boostad_get_code.estate', 'streetAddress') }}
              </div>
            </div>
          </v-img>
        </v-row>
        <br />
        <v-container fluid>
          <v-row>
            <!-- DOCUMENT LIST -->
            <v-col cols="8" xl="9" lg="9" md="9" sm="10">
              <div v-if="obj_url_exists || documents.length > 0">
                <label>{{ getLang('v6_customer', 'lbl_docs') }}:</label>
                <br />
                <br />

                <!-- om  objektsbeskrivning finns-->
                <template v-if="obj_url_exists">
                  <v-row>
                    <v-btn
                      small
                      text
                      color="primary"
                      @click="get_object(sameorigin)"
                    >
                      {{
                        getLang('v6_customer', 'lbl_objdesc').toUpperCase()
                      }}</v-btn
                    >

                    <v-icon style="font-size: 0.93em; margin-left: -10px"
                      >mdi-chevron-right</v-icon
                    >
                  </v-row>
                </template>

                <template v-for="(doc, index) in documents">
                  <v-row v-if="index < doc_cnt" :key="index">
                    <v-btn
                      small
                      text
                      color="primary"
                      @click="get_document(index)"
                    >
                      {{ trunk_name(doc.name) }}
                    </v-btn>

                    <v-icon style="font-size: 0.93em; margin-left: -10px"
                      >mdi-chevron-right</v-icon
                    >
                  </v-row>
                </template>
                <v-row v-if="documents.length > doc_cnt">
                  <v-btn
                    small
                    text
                    color="primary"
                    @click="show_all_documents()"
                    >{{
                      getLang('v6_customer', 'lbl_moredocs').toUpperCase()
                    }}</v-btn
                  >
                  <v-icon style="font-size: 0.93em; margin-left: -10px"
                    >mdi-chevron-right</v-icon
                  >
                </v-row>
                <v-row></v-row>
                <v-row v-if="this.org_key == '2283'">
                  <span style="font-size: 7px; margin: 30px 20px 0px 15px"
                    >Samtliga visningsdeltagare får en skapad sökprofil baserad
                    på visningsbostaden och det geografiska området. Detta är en
                    tjänst som hjälper dig hitta din bostad. Önskar du inte få
                    tips på liknande bostäder kan du enkelt avanmäla dig från
                    tjänsten via mailet innehållande bostadstips från oss.</span
                  >
                </v-row>
                <!-- </v-container>-->
              </div>
            </v-col>
            <v-col class="pa-0" cols="4" xl="3" lg="3" md="3" sm="2">
              <!-- BROKER INFO        max-width="110px" -->
              <div :class="{ offsetUp: org_key !== '2282' }">
                <v-card style="min-width: 103px" tile flat :color="cardColor">
                  <!-- class="mx-auto" src="/img/infoping_watermark.png":color="cardColor" -->
                  <v-img class="cardImage">
                    <v-img
                      alt="Mäklarens profilbild"
                      :aspect-ratio="1 / 1"
                      position="top"
                      :src="get_value('boostad_get_code.broker', 'url_img')"
                    /><v-img />

                    <div style="line-height: 1; padding: 5px">
                      <b>
                        <label
                          :class="[
                            darkSMS ? 'vCardTextRegDark' : 'vCardTextReg',
                          ]"
                          style="font-size: 12px"
                        >
                          {{ get_value('boostad_get_code.broker', 'userName') }}
                        </label>
                      </b>
                      <br />
                      <label
                        :class="[darkSMS ? 'vCardTextRegDark' : 'vCardTextReg']"
                        style="font-size: 12px"
                      >
                        {{ get_value('boostad_get_code.broker', 'title') }}
                      </label>
                      <br />
                      <label style="font-size: 10px; padding-bottom: 10px">
                        <a
                          :class="[darkSMS ? 'linkDarkMode' : 'linkLightMode']"
                          :href="'tel:' + this.broker_ph"
                          >{{
                            get_value('boostad_get_code.broker', 'cellPhone')
                          }}
                        </a>
                      </label>
                    </div>
                  </v-img>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <br />
        <div v-if="demo">
          <v-divider></v-divider>
          <br />

          <label
            >Det här är en demosida för bostad.site, du har hamnat här för att
            du inte har någon visningskod eller för att du har fel kod.</label
          >
        </div>

        <br v-if="demo" />
        <v-divider v-if="ad_lanekoll == false"></v-divider>

        <!-- LÅNEKOLL -->
        <lanekoll v-if="ad_lanekoll" class="pd-zero" />

        <!-- SÖDERBERG & PARTNERS -->

        <soderberg v-else-if="ad_soderberg" :adType="adType" />
        <soderberg_split v-else-if="ad_soderberg_split" :adType="adType" />

        <!-- Endast värdering -->

        <valuation v-else-if="ad_valuation" :adType="adType" />
      </v-container>
    </v-container>
  </div>
</template>

<script>
import att from '@/pages/attachmentView.vue';
import documents from '@/pages/AllDocuments.vue';
import lanekoll from '@/components/ads/lanekoll.vue';
import soderberg from '@/components/ads/soderberg.vue';
import soderberg_split from '@/components/ads/soderberg_split.vue';
import valuation from '@/components/ads/valuation.vue';

import Mixin from '@/Mixin.js';

import get from 'lodash/get';
export default {
  name: 'Reg',
  mixins: [Mixin],
  components: {
    att,
    documents,
    lanekoll,
    soderberg,
    soderberg_split,
    valuation,
    //  broker_ad,
  },

  data() {
    return {
      documents: [],
      square_icon: false,
      rectangle_icon: false,
      header_icon_height: '70',
      demo: false,
      loading: true,
      ad_lanekoll: false,
      ad_valuation: false,
      ad_soderberg: false,
      ad_soderberg_split: false,
      sameorigin: false,
      broker_ph: null,
      ads: null,
      adType: 'reg_sms',
      obj_url_exists: false,
      doc_cnt: 0,
    };
  },

  created() {},
  beforeDestoy() {},
  beforeMount() {
    this.broker_ph = this.get_value('boostad_get_code.broker', 'cellPhone');

    if (get(this.$store.state.tellus, 'boostad_get_code.id', 0) == 0)
      this.demo = true;

    var obj_url = this.get_doc_url('object');
    if (obj_url && obj_url != null && obj_url.length > 0)
      this.obj_url_exists = true;

    var result = '';
    this.documents = this.get_documents();

    this.doc_cnt = 34;
    if (this.obj_url_exists) this.doc_cnt--;
    // ---------------------------------------------
    // ADS
    // ---------------------------------------------

    result = this.get_items('boostad_get_code.ads');
    // ONE ADS
    if (result.length == 1) {
      var ad = result[0].name;

      if (ad == 'lanekoll') this.ad_lanekoll = true;
      else if (ad == 'soderberg') this.ad_soderberg = true;
      else if (ad == 'valuation') this.ad_valuation = true;
    }
    // TWO ADS
    else if (result.length == 2) {
      var ad1 = result[0].name;
      var ad2 = result[1].name;

      if (ad1 == 'soderberg' || ad2 == 'soderberg')
        this.ad_soderberg_split = true;
    }

    // OBJECTSBESKRIVNING UTAN IFRAM
    result = this.get_value('boostad_get_code.branding', 'X-Frame-Options');

    if (result == 'sameorigin') this.sameorigin = true;

    // HEADER ICON
    result = this.get_value('boostad_get_code.branding', 'header_icon');

    if (result == 'square') this.square_icon = true;
    else if (result == 'rectangle') this.rectangle_icon = true;
    else {
      this.square_icon = false;
      this.rectangle_icon = false;
    }

    //HEADER ICON HEIGHT
    var header_icon_height = this.get_value(
      'boostad_get_code.branding',
      'header_icon_height'
    );

    if (!isNaN(parseFloat(header_icon_height)) && isFinite(header_icon_height))
      this.header_icon_height = header_icon_height;
    else this.header_icon_height = '70';
  },
  computed: {
    cardColor() {
      // if (this.darkSMS) return 'var(--vCard-reg-color)';
      // else return 'var(--v-primary-base)';
      return 'var(--vCard-reg-color)';
    },

    org_key() {
      var org = this.get_value('boostad_get_code.code_attributes', 'org_key');
      if (org) return org;
      else return '';
    },
  },
  methods: {
    show_all_documents() {
      this.$store.dispatch('tellus/show_documents', true);
      this.$store.dispatch('tellus/show_attachment', false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* .broker_pos {
  border: 1px solid #323232;
  background-color: white;
  box-shadow: 4px 4px 4px 0px rgb(184 183 183);
  left: 10px;
}
 */

.desktop_view {
  /* border: 1px solid #323232;*/
  width: 600px;
  box-shadow: 4px 4px 4px 4px rgb(184 183 183);
}

.offsetUp {
  margin-top: -45px;
  /* padding-right: 10%; */
}

.pd-zero {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.document_list {
  margin-top: 20px !important;
}
.pd-80 {
  margin-top: 5 !important;
  margin-bottom: 5 !important;
  margin-left: 5 !important;
  margin-right: 5 !important;
  padding-top: 20px !important;
  padding-bottom: 100px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.img-border {
  border-bottom: 1px solid #323232;
}

.compact-form {
  transform: scale(0.8);
  transform-origin: left;
}

#parent::after {
  content: '';
  width: 100%;
  height: 23px;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#child {
  position: relative;
  z-index: 2;
}
/*
a:link {
  color: white;


  text-decoration: none;
} */
.color {
  color: var(--v-primary-base);
}

.theme--light.v-divider {
  border-color: var(--divider-color) !important;
}
.cardImage {
  background: var(--vCard-image-reg-url);

  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

a.linkDarkMode {
  color: var(--vCard-text-reg-color-dark);
}
a.linkDarkMode:link {
  color: var(--vCard-text-reg-color-dark);
}
a.linkDarkMode:visited {
  text-decoration: none;
  color: var(--vCard-text-reg-color-dark);
}
a.linkDarkMode:hover {
  text-decoration: underline;
  color: var(--vCard-text-reg-color-dark);
}
a.linkDarkMode:active {
  text-decoration: none;
  color: var(--vCard-text-reg-color-dark);
}

a.linkLightMode {
  color: var(--vCard-text-reg-color) !important;
}
a.inkLightMode:link {
  color: var(--vCard-text-reg-color) !important;
}
a.inkLightMode:visited {
  text-decoration: none;
  color: var(--vCard-text-reg-color) !important;
}
a.inkLightMode:hover {
  text-decoration: underline;
  color: var(--vCard-text-reg-color) !important;
}
a.inkLightMode:active {
  text-decoration: none;
  color: var(--vCard-text-reg-color) !important;
}
</style>
