
import tellus from '../src/store/tellus'
import get from "lodash/get";
export function get_header()
{   
  
  let items = get(tellus.state, 'boostad_get_code.code_attributes', []);
  let phone_number = '';
  
  const item = items.find((item) => item.name.toLowerCase() === 'phone_number');
  if (item) {
    phone_number = get(item, 'value', '');
  }
 
    //console.log(infopingstore)
    let answer = {
        'Content-Type': 'application/json',
        'Source-Id': get(tellus.state,'source_id',''),
        'Source-Name': get(tellus.state,'source_name',''),
        'Source-Number': phone_number
      };
      return answer;
}