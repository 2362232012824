<template>
  <!--class="text-center"  max-width="40%"-->
  <div>
    <v-container style="padding-left: 5px; padding-right: 5px">
      <br />
      <v-row no-gutters justify="center">
        <v-img
          @click="openBrokerAd()"
          :src="get_logo()"
          max-width="50%"
          max-height="80px"
          contain
        >
        </v-img>
      </v-row>

      <br />
      <v-row justify="center">
        <label
          style="font-size: 12px; padding-bottom: 10px"
          @click="openBrokerAd()"
        >
          VILL DU HA EN KOSTNADSFRI VÄRDERING?</label
        >
        <br />
      </v-row>
      <!--  <v-row justify="center">
        <label class="text_box" @click="openBrokerAd()">
          <br />
        {{ regReadMore }}
        </label>
      </v-row>-->
      <v-row justify="center">
        <v-btn
          small
          style="width: 50%"
          color="primary"
          outlined
          @click="openBrokerAd()"
          >Läs mer</v-btn
        >
      </v-row>
      <br />
      <br />
      <v-row>
        <v-col cols="12">
          <!--   <v-row no-gutters justify="end">
            <v-img @click="openBrokerAd()" max-width="40%" :src="get_logo()">
            </v-img>
          </v-row>-->

          <!-- <v-row justify="center">
            <v-btn
              outlined
              small
              style="width: 250px; margin-top: 10px"
              dark
              color="#049cdc"
              @click="openRegform()"
              >LÄS MER</v-btn
            >
          </v-row> -->

          <!-- broker-->
          <v-dialog v-model="regform_broker" v-if="regform_broker">
            <regformBroker
              :header="regHeader"
              :subHeader="regSubHeader"
              :logo="regLogo"
              :confirmContact="regConfirmContact"
              :terms="regTerms"
              :readMore="regReadMore"
              @closeMe="
                () => {
                  closeRegform();
                }
              "
            />
          </v-dialog>

          <!-- fri värdering web --->
          <v-dialog width="100%" height="100%" v-model="goToWeb">
            <v-card>
              <div class="text-right">
                <v-btn
                  class="sticky"
                  style="margin-left: 5px; transform: scale(0.8)"
                  icon
                  @click="goToWeb = false"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
              <div class="iframe-container">
                <iframe
                  :src="this.get_ad_value('valuation', 'read_more')"
                  allowfullscreen
                >
                </iframe>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Mixin from '@/Mixin.js';
import regformBroker from '@/components/Dialogs/regformBroker.vue';

export default {
  name: 'soderberg',
  mixins: [Mixin],
  props: {
    adType: String,
  },
  data() {
    return {
      regform_broker: false,
      regHeader: '',
      regSubHeader: '',
      regLogo: '',
      regConfirmContact: '',
      regTerms: '',
      regReadMore: this.get_ad_value('valuation', 'read_more'),

      goToWeb: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    openRegformBroker() {
      this.regHeader = 'Boka kostnadsfri värdering!';
      this.regSubHeader = 'Fyll i uppgifterna nedan så kontaktar vi dig';
      // 'Går du i försäljningstankar och behöver veta vad din bostad är värd? En värdering är helt kostnadsfri hos oss och kräver ingen motprestation från din sida.';
      this.regLogo = this.get_logo();
      this.regConfirmContact = 'Vi kommer att kontakta dig';

      this.regTerms = this.get_ad_value('valuation', 'terms');
      this.regReadMore = this.get_ad_value('valuation', 'read_more');

      // this.adType = 'valuation';
      //posta till loggen
      this.$store.dispatch('tellus/logThis', {
        value: 'broker_ad_' + this.adType,
      });
      this.regform_broker = true;
    },

    openBrokerAd() {
      if (this.get_ad_value('valuation', 'lead_type') == 'regform') {
        this.openRegformBroker();
      } else {
        //posta till loggen
        this.$store.dispatch('tellus/logThis', {
          value: 'broker_ad_' + this.adType,
        });
        this.goToWeb = true;
      }
    },

    closeRegform() {
      this.regform_broker = false;
    },
  },
  components: {
    regformBroker,
  },
};
</script>

<style scoped>
/*
.centerContent {
  text-align: center !important;
  align-items: center !important;
}
'Mochiy Pop P One';*/
.lkFont {
  font-family: 'Bangers';
  font-size: 20px;
  color: #049cdc;
  text-align: center !important;
}
.text_box {
  font-size: 12px;
  margin-top: 10px;

  margin-bottom: 10px;
  text-align: justify;
  word-break: keep-all;
}

/* a {
  color: #43337b;
} */
</style>
