import Vue from 'vue'
import App from './App.vue'
import 'es6-promise/auto'
import storeInit from './store';
import vuetify from './plugins/vuetify';
import './assets/fonts/fonts.scss';
import './assets/css/main.scss';

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false
const store = storeInit();

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
