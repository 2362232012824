import { 
  QUERY_URL_REST2,
  QUERY_URL,
} from "@/constants.js";

import axios from 'axios';
import {get_header} from '../f';

export default {


  async boostad_get_code(obj) {
    const headers = get_header();
    //console.log(headers)
    
    const funktion='boostad/get_code/'+obj.code;
    // var url='https://infoping.net/REST2/ask/Q/'+funktion;
    var url=QUERY_URL+funktion;
    
    return await axios
    .get(url, {
      headers: headers,
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
      
  },
  async bostadsite_doc(obj) {
    const headers = get_header();
    const funktion='bostadsite/document/';
    var url=QUERY_URL+funktion+obj.customerId+"/"+obj.fileId;
    return await axios
    .get(url, {
      headers: headers,
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
    async bostadsite_get(obj) {
      const headers = get_header();
    
    const funktion='bostadsite/code/'+obj.estate_code+'/'+obj.width;
    var url=QUERY_URL+funktion;
    return await axios
    .get(url, {
      headers: headers,
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
      
  },
  

  async get_msg_layout(obj) {
    const headers = get_header();
    const funktion='msg_layout';
    var url=QUERY_URL+funktion;
    var post_data= JSON.stringify(obj);
    
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async getbase64(url) {
    return axios
    .get(url, {
      responseType: 'arraybuffer'
    })
    .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  },
// INFOPING
async ip_function(obj) {
  const headers = get_header();
  var url=QUERY_URL_REST2+obj.function;
  var post_data= JSON.stringify(obj.question);
  //console.log(url,post_data)
  return await axios
    .post(url, post_data, {
      headers: headers,
    })
    .then((res) => {
      
  //console.log(res.data)
      return res.data;
    })
    .catch((e) => {
      return { status: 0, error: e.message };
    });
},
async ip_post(obj) {
  const headers = get_header();
  var url=QUERY_URL+obj.end_point;
  var post_data= JSON.stringify(obj.data);
  return await axios
    .post(url, post_data, {
      headers: headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return { status: 0, error: e.message };
    });
},


async lang_chk(lang) {
  const headers = get_header();
    
  const funktion='lang_chk';
  
  const url =
  QUERY_URL +
  funktion +'/'+lang;
    
  return await axios
    .get(url, {
      headers: headers,
    })
    .then((res) => {
      var ans = res.data;
      return ans;
    })
    .catch((e) => {
      alert('ERROR:' + e);
      return null;
    });
  
  
},

async lang_filter(obj) {
  const headers = get_header();
    
  const funktion='lang_filter';
  var lang='sv';
  var filter='all';
  
  if(obj.lang)
  {
    if(obj.lang)
  {

    if(obj.lang.trim()!='')
    lang=obj.lang;
  }
  if(obj.filter)
  {

    if(obj.filter.trim()!='')
    filter=obj.filter;
  }
  const url =
  QUERY_URL +
  funktion +'/'+lang+ '/'+filter;
    
  return await axios
    .get(url, {
      headers: headers,
    })
    .then((res) => {
      var ans = res.data;
      
      return ans;
    })
    .catch((e) => {
      alert('ERROR:' + e);
      return null;
    });
  }
  else
  return null;
  
},

async logPost(obj){
  const headers = get_header();

  const funktion='bostadsite/log'
  var url=QUERY_URL+funktion;
  var post_data= JSON.stringify(obj);

  return await axios
  .post(url, post_data, {
    headers: headers,
  });
    
},
  
};
