import { render, staticRenderFns } from "./soderberg_split.vue?vue&type=template&id=1fa05d1e&scoped=true&"
import script from "./soderberg_split.vue?vue&type=script&lang=js&"
export * from "./soderberg_split.vue?vue&type=script&lang=js&"
import style0 from "./soderberg_split.vue?vue&type=style&index=0&id=1fa05d1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa05d1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VContainer,VDialog,VDivider,VIcon,VImg,VRow})
