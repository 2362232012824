//----------------------------------------------
// SKARPA
//----------------------------------------------
 export const QUERY_URL_REST2 = 'https://infoping.net/REST2/ask/Q/json/?function=';
 export const QUERY_URL = 'https://infoping.net/REST2/ask/Q/';
 export const QUERY_URL_BOSTAD = 'https://infoping.net/REST2/ask/Q/bostadsite/';

//----------------------------------------------
// LOCAL HOST
//----------------------------------------------
// export const QUERY_URL_REST2 = 'http://localhost:57303/ask/Q/json/?function=';
// export const QUERY_URL = 'http://localhost:57303/ask/Q/';
// export const QUERY_URL_BOSTAD = 'http://localhost:57303/ask/Q/bostadsite/';



//----------------------------------------------
//DEV
//----------------------------------------------
//export const QUERY_URL_REST2 = 'https://dev.infoping.se/REST2/ask/Q/json/?function=';
//export const QUERY_URL = 'https://dev.infoping.se/REST2/ask/Q/';
//export const END_USER_IP ='84.19.145.194';