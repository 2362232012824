<template>
  <div>
    <div v-if="show_documents" id="top_pdf">
      <v-container class="border">
        <v-row>
          <v-col cols="12">
            <v-btn text color="primary" @click="close()">
              <v-icon left>mdi-arrow-left-circle</v-icon>
              Tillbaka
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <!-- SHOW DOCUMENT
    <att
      v-if="show_attachment"
      :base64="base64"
      :ext="ext"
      :file_name="file_name"
    /> -->
      <v-col cols="8">
        <v-row>
          <v-btn small text color="primary" @click="get_object()"
            >objektbeskrivning</v-btn
          >

          <!-- <v-icon style="font-size: 0.93em; margin-left: -10px"
          >mdi-chevron-right</v-icon
        > -->
        </v-row>
        <template v-for="(doc, index) in documents">
          <v-row :key="index">
            <v-btn small text color="primary" @click="get_document(index)"
              >{{ doc.name }}
            </v-btn>

            <!-- <v-icon style="font-size: 0.93em; margin-left: -10px"
            >mdi-chevron-right</v-icon
          > -->
          </v-row>
        </template>
        <!-- </v-container>-->
      </v-col>
    </div>
  </div>
</template>
<script>
//import rest2Api from '@/api/rest2Api.js';
//import att from '@/pages/attachmentView.vue';
import Mixin from '@/Mixin.js';
// import axios from "axios";
//import get from 'lodash/get';
export default {
  name: 'Alldocuments',
  mixins: [Mixin],
  components: {
    // att,
  },

  data() {
    return {
      documents: [],
      base64: null,
      ext: null,
      file_name: null,
      square_icon: false,
      rectangle_icon: false,
      // header_icon_pos: 'center top',
      header_icon_height: '70',
      //radios: null,
      budklar: true,
      street_address: null,
      loading: true,
    };
  },
  beforeMount() {
    // GET DOCUMENTS
    this.documents = this.get_documents();
  },
  created() {},
  beforeDestoy() {},
  computed: {},
  methods: {},
};
</script>
