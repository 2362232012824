<template>
  <v-dialog persistent v-model="isDialogOpen" width="300">
    <v-card>
      <v-card-title primary-title>{{ headerText }}</v-card-title>

      <v-card-text class="bodyText">{{ bodyText }}</v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="secondary"
          v-if="showCancelButton"
          text
          @click="onCancelClick"
          >Ångra</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onConfirmClick">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//import all_mixin from '@/all_mixin.js';

export default {
  name: 'SimpleAlert',
  // mixins: [all_mixin],

  data() {
    return {};
  },
  computed: {
    isDialogOpen: {
      get: function () {
        if (this.$store.state.tellus.alertData)
          return this.$store.state.tellus.alertData.show;
        else return false;
      },
      set: function () {
        // do nothning
      },
    },
    headerText() {
      if (this.$store.state.tellus.alertData)
        return this.$store.state.tellus.alertData.headerText;
      else return '';
    },
    bodyText() {
      if (this.$store.state.tellus.alertData)
        return this.$store.state.tellus.alertData.bodyText;
      else return '';
    },
    showCancelButton() {
      if (this.$store.state.tellus.alertData)
        return this.$store.state.tellus.alertData.showCancelButton;
      else return true;
    },
  },
  methods: {
    onDialogClosed() {
      this.$store.dispatch('tellus/showAlert', null);
    },
    onConfirmClick() {
      if (
        this.$store.state.tellus.alertData &&
        this.$store.state.tellus.alertData.confirmAction
      )
        this.$store.state.tellus.alertData.confirmAction();
      this.onDialogClosed();
    },
    onCancelClick() {
      if (
        this.$store.state.tellus.alertData &&
        this.$store.state.tellus.alertData.cancelAction
      )
        this.$store.state.tellus.alertData.cancelAction();
      this.onDialogClosed();
    },
  },
};
</script>

<style scoped>
.bodyText {
  white-space: pre-wrap;
}
</style>
