import get from "lodash/get";

import { base64StringToBlob } from "blob-util";
import rest2Api from "@/api/rest2Api.js";
import FileSaver from "file-saver";
import { mapGetters } from "vuex";
export default {
  components: {
    FileSaver,
  },
  computed: {
    ...mapGetters({
      darkSMS: "tellus/get_dark_mode",
      darkLogo: "tellus/get_dark_logo",
    }),
    show_attachment() {
      return get(this.$store.state.tellus, "show_attachment", false);
    },

    show_documents() {
      return get(this.$store.state.tellus, "show_documents", false);
    },
    view_document_get(what) {
      return get(this.$store.state.tellus, "view_document." + what, null);
    },
  },
  methods: {
    clone_obj(obj) {
      if (obj) return JSON.parse(JSON.stringify(obj));
      else return null;
    },
    str2int(str) {
      var answer = -1;
      if (str) {
        str = str.replace(/[^0-9]/g, "");
        if (!isNaN(parseFloat(str)) && isFinite(str)) {
          answer = parseInt(str);
        }
      }
      return answer;
    },
    trunk_name(value) {
      let answer = value;
      if (value) {
        let cnt = 20;
        if (value.length > cnt) answer = value.substring(0, cnt) + "...";
      }
      return answer;
    },
    get_ad_value(ad_name, item_name) {
      let result = null;
      let ads = this.get_items("boostad_get_code.ads");
      if (ads && ads.length > 0) {
        ads.forEach((element) => {
          if (element.name == ad_name) {
            result = element[item_name];
          }
        });
      }
      return result;
    },
    get_value(path, name) {
      let result = null;
      let items = this.get_items(path);
      if (items && items.length > 0) {
        var item = items.filter(
          (item) => item.name.toLowerCase() === name.toLowerCase()
        );
        if (item.length == 1) result = get(item[0], "value", "");
      }
      return result;
    },
    get_items(path) {
      return get(this.$store.state.tellus, path, []);
    },

    get_doc_url(name) {
      let result = null;
      let items = this.get_items("boostad_get_code.estate_document");
      if (items && items.length > 0) {
        var item = items.filter(
          (item) => item.name.toLowerCase() === name.toLowerCase()
        );
        if (item.length == 1) result = get(item[0], "url", "");
      }

      return result;
    },
    get_documents() {
      let result = [];
      let items = this.get_items("boostad_get_code.estate_document");
      if (items && items.length > 0) {
        var documents = items.filter(
          (item) => item.name.toLowerCase() != "object"
        );
        if (documents.length > 0) result = documents;
        else result = [];
      }

      return result;
    },
    get_object(sameorigin) {
      var object_description=''
      var log= new Object();
      if (sameorigin) {
        object_description=this.get_doc_url("Object");
        //console.log(object_description)

        //LOG CODE
        log.what = "click";
        log.value = "document";
        log.info = object_description;
        this.$store.dispatch("tellus/logThis", log);

        window.open(this.get_doc_url("Object"));
      } else {

        object_description=this.get_doc_url("Object");

        var document_base64 = new Object();
        document_base64.extension = "url";
        document_base64.file_name = this.get_doc_url("Object");

        this.$store.dispatch("tellus/view_document", document_base64);
        this.$store.dispatch("tellus/show_attachment", true);
        
        //LOG CODE
        log.what = "click";
        log.value = "document";
        log.info = object_description;
        this.$store.dispatch("tellus/logThis", log);
      }
    },
    get_document_value(name) {
      return get(this.$store.state.tellus, "view_document." + name, null);
    },
    get_document(idx) {
      var documents = this.get_documents();
      var document = documents[idx];

      var document_base64 = new Object();
      document_base64.extension = document.extension;
      document_base64.fileId = document.id;
      document_base64.file_name = document.name+'.' + document.extension;
      var base64 = this.get_value("base64", document.id);
      if (!base64 || base64 == null || base64 == "") {
        var obj = new Object();
        obj.customerId = this.get_value(
          "boostad_get_code.code_attributes",
          "customerId"
        );
        obj.fileId = document.id;
        rest2Api.bostadsite_doc(obj).then((ans) => {
          document_base64.base64 = ans.base64;

          this.$store.dispatch("tellus/view_document", document_base64);
          this.$store.dispatch("tellus/show_attachment", true);

          var base64_lst = this.get_items("base64");

          if (!base64_lst || base64_lst == null) base64_lst = new Array();

          var copy = JSON.parse(JSON.stringify(base64_lst));
          var item64 = new Object();
          item64.name = document.id;
          item64.value = ans.base64;
          copy.push(item64);

          this.$store.dispatch("tellus/save_base64", copy);
        });
      } else {
        document_base64.base64 = base64;

        this.$store.dispatch("tellus/view_document", document_base64);
        this.$store.dispatch("tellus/show_attachment", true);
      }
      
        //LOG CODE
        var log= new Object();
        log.what = "click";
        log.value = "document";
        log.info = document_base64?.file_name;
        this.$store.dispatch("tellus/logThis", log);
        // posta till loggen
        // this.$store.dispatch("tellus/logThis", {value:'document',info:document_base64?.file_name});
    },

    get_logo() {
      let result = null;
      if (this.darkSMS) {
        result = this.get_value("boostad_get_code.branding", "dark_logo");

        if (!result || result == null || result == "")
          result = this.get_value("boostad_get_code.branding", "logo");
      } else result = this.get_value("boostad_get_code.branding", "logo");

      return result;
    },
    get_back_color(){
      let result = '#ffffff';
      if (this.darkSMS) {
        result=null;

      } else result = this.get_value("boostad_get_code.branding", "back_color");

      return result;
    },


    close() {
      this.$store.dispatch("tellus/view_document", null);
      if (this.show_documents) {
        if (this.show_attachment) {
          this.$store.dispatch("tellus/show_attachment", false);
          this.$store.dispatch("tellus/show_documents", true);
        } else {
          this.$store.dispatch("tellus/show_attachment", false);
          this.$store.dispatch("tellus/show_documents", false);
        }
      } else {
        this.$store.dispatch("tellus/show_attachment", false);
        this.$store.dispatch("tellus/show_documents", false);
      }
    },
    save(base64, file_name) {
      const blob = new base64StringToBlob([base64], {
        type: "application/pdf",
      });
      FileSaver.saveAs(blob, file_name);
    },

    format_currency(value) {
      let num_char = "";
      for (var i = 0; i < value.length; i++) {
        var c = value.charAt(i);
        if (!isNaN(c)) num_char = num_char + c;
      }
      num_char = String(num_char).replace(/(.)(?=(\d{3})+$)/g, "$1 ") + " kr";

      return num_char;
    },
    get_char(value, idx) {
      let num_char = "";
      for (var i = value.length - 1; i >= 0; i--) {
        var c = value.charAt(i);
        if (!isNaN(c)) num_char = num_char + c;
      }

      if (idx < num_char.length) return num_char.charAt(idx);
      else return "";
    },
    format_short_date(value) {
      var options = {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var today = new Date(value);

      value = today.toLocaleDateString("sv-SE", options);
      return value;
    },

    alertBox(headerText, bodyText) {
      this.$store.dispatch("tellus/showAlert", {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => (this.primary_color = "#999999"),
      });
    },

    LightenDarkenColor(hex, percent) {
      // strip the leading # if it's there
      hex = hex.replace(/^\s*#|\s*$/g, "");

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if (hex.length == 3) {
        hex = hex.replace(/(.)/g, "$1$1");
      }

      var r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16);

      return (
        "" +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
          .toString(16)
          .substr(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
          .toString(16)
          .substr(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
          .toString(16)
          .substr(1)
      );
    },
    store_colors(back_color) {
      var colors = this.get_color_array(back_color);

      this.$store.dispatch("tellus/set_colors", colors);
    },
    get_color_array(back_color) {
      var color_head = back_color;
      var color_odd = this.LightenDarkenColor(color_head, 80);
      var color_even = this.LightenDarkenColor(color_head, 90);
      var color_text = "000000";
      var colors = new Array();
      var color = new Object();

      // HEAD
      color = new Object();
      color.name = "head";
      color.value = color_head;
      colors.push(color);

      // ODD
      color = new Object();
      color.name = "odd";
      color.value = color_odd;
      colors.push(color);

      // EVEN
      color = new Object();
      color.name = "even";
      color.value = color_even;
      colors.push(color);

      // TEXT
      color = new Object();
      color.name = "text";
      color.value = color_text;
      colors.push(color);

      return colors;
    },
    get_color(name) {
      var answer = "000000";
      var colors = get(this.$store.state.tellus, "colors", []);
      if (colors.length > 0) {
        var values = colors.filter((items) => {
          return items.name.toLowerCase().match(name.toLowerCase());
        });
        answer = get(values[0], "value", answer);
      }

      return answer;
    },
    getLang(c, w) {
      try {
        var filtered = this.$store.state.tellus.lang_filter.items.filter(
          (x) => x.controller == c && x.what == w
        );

        return filtered[0].text;
      } catch {
        return w;
      }
    },
    log(write, v1, v2, v3, v4) {
      if (write) {
        if (v1 && v2 && v3 && v4) console.log(v1, v2, v3, v4);
        else if (v1 && v2 && v3) console.log(v1, v2, v3);
        else if (v1 && v2) console.log(v1, v2);
        else if (v1) console.log(v1);
      }
    },
  },
};
