<template>
  <div>
    <div v-if="is_attachment" id="top_pdf">
      <v-container class="border">
        <v-row>
          <v-col cols="6">
            <v-btn
              text
              :class="{ 'white--text': darkSMS }"
              :color="back_color"
              @click="close()"
            >
              <v-icon :class="{ 'white--text': darkSMS }" left
                >mdi-arrow-left-circle</v-icon
              >
              Tillbaka
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              v-if="is_pdf"
              text
              :class="{ 'white--text': darkSMS }"
              :color="back_color"
              @click="save(base64, file_name)"
            >
              SPARA
              <v-icon :class="{ 'white--text': darkSMS }" right
                >mdi-cloud-download</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- <PDFdoc /> -->
      <div v-if="is_pdf" class="pdf-container">
        <pdf :base64="base64" :file_name="file_name" />
      </div>

      <div v-else-if="is_url" class="iframe-container">
        <iframe :src="file_name" allowfullscreen> </iframe>
      </div>
    </div>

  </div>
</template>
<script>

import pdf from '@/pages/v_pdf.vue';

import Mixin from '@/Mixin.js';
export default {
  name: 'AttachmentView',
  mixins: [Mixin],
  props: { base64: String, ext: String, file_name: String },

  data() {
    return {
      // url: this.get_url(),
      is_attachment: true,
      is_pdf: false,
      is_url: false,
      back_color: '',
    };
  },
  components: {
    pdf,
    //  V_TXT,
    //  V_IMG,
  },
  created() {},
  beforeMount() {
    // ATTACHMENT PDF
    if (this.ext == '.pdf' || this.ext == 'pdf') this.is_pdf = true;
    else this.is_pdf = false;

    // ATTACHMENT URL
    if (this.ext == 'url') this.is_url = true;
    else this.is_url = false;

    //BACK COLOR
    this.back_color = this.get_back_color();

  },
  computed: {
    // is_txt() {
    //   var answer = false;
    //   var file_name = get(
    //     this.$store.state.infopingstore,
    //     "attachment_view.file_name",
    //     ""
    //   );
    //   if (file_name.endsWith(".txt")) answer = true;
    //   return answer;
    // },
    // is_img() {
    //   var answer = false;
    //   var file_name = get(
    //     this.$store.state.infopingstore,
    //     "attachment_view.file_name",
    //     ""
    //   );
    //   answer = this.is_image(file_name);
    //   return answer;
    // },
  },
  methods: {
    // get_class_txt() {
    //   return "error-txt-white";
    // },
    // get_class_read() {
    //   return "error-txt-green";
    // },
    // get_class_close() {
    //   return "error-txt-green";
    // },
    // get_class_header() {
    //   return "error-txt-green";
    // },
    // get_url() {
    //   return get(this.$store.state.infopingstore, "attachment_view.url", "");
    // },
    // close() {
    //   this.$store.dispatch("tellus/show_attachment", false);
    // },
    // save(){
    //   const blob = new base64StringToBlob([this.base64], { type: 'application/pdf' })
    //   const link = document.createElement('a')
    //   link.href = URL.createObjectURL(blob)
    //   link.download = this.file_name
    //   link.click()
    //   URL.revokeObjectURL(link.href)
    //     },
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 0;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 91vh;
  left: 0;
  position: relative;
  width: 100%;
  top: 0;
}
.pdf-container {
  border: 0;
  height: 91vh;
  left: 0;
  position: relative;
  width: 100%;
  top: 0;
}
.border {
  border: 0;
  height: 9vh;
  left: 0;
  position: relative;
  width: 100%;
  top: 0;
}
</style>
