import {
  BASE64,
  SHOW_ATTACHMENT,
  SHOW_DOCUMENTS,
  VIEW_DOCUMENT,
  SHOW_ALERT,
  SET_ANSWER,
  LANG_FILTER,
  BOOSTAD_GET_CODE,
  SOURCE_ID,
  SOURCE_NAME,
} from "./types.js";

import rest2Api from "@/api/rest2Api.js";
import demo from "@/demo_broker.js";
import get from "lodash/get";

export default {
//----------------------------------------------
// VUE PROJEKT
//----------------------------------------------
async fetch_source_id({ commit },obj) {


  if(obj)
  {
    commit(SOURCE_ID, obj);

  }
  }
  
,async fetch_source_name({ commit },obj) {


  if(obj)
  {

    commit(SOURCE_NAME, obj);

  }
  },
  // INFOPING
  async fetch_boostad_get_code({ commit }, obj) {
    if (obj.code != "") {
      var ans = await rest2Api.boostad_get_code(obj);
      if (ans.id>0) {
        commit(BOOSTAD_GET_CODE, ans);
        return ans;
      }
    }
    // DEMO DATA
    var no_code = demo.demo_broker_obj();

    commit(BOOSTAD_GET_CODE, no_code);
    return no_code;
  },
  async save_base64({ commit }, base64_lst) {
    
        commit(BASE64, base64_lst);
        return base64_lst;
  },




  async save_bostadsite_doc({ commit }, obj) {
    commit(BASE64, obj);
    return obj;
  },

  async show_attachment({ commit }, obj) {
    commit(SHOW_ATTACHMENT, obj);
    return obj;
  },
  async show_documents({ commit }, obj) {
    commit(SHOW_DOCUMENTS, obj);
    return obj;
  },
  async view_document({ commit }, obj) {
    commit(VIEW_DOCUMENT, obj);
    return obj;
  },

  // brings up a simple alert message box
  async showAlert({ commit }, alertData) {
    commit(SHOW_ALERT, alertData);
  },




  async logThis({ state }, data) {

    if (data) {
      var obj = new Object();
      obj.code_id = get(state, "boostad_get_code.id", null);
      obj.what = get(data,"what","click");
      obj.value = get(data,"value","");
      obj.info = get(data,"info","");

      if (obj.code_id != null) rest2Api.logPost(obj);
    }
  },
 

  async commit_answer({ commit }, obj) {
    commit(SET_ANSWER, obj);
  },

  async fetch_lang_filter({ commit }, obj) {

    var ans = await rest2Api.lang_filter(obj);
  
      commit(LANG_FILTER, ans);
      return ans;
  }, 
};
