<template>
  <div>
    <!-- Visa objektsbeskrivningen -->
    <v-container
      v-if="show_attachment"
      fluid
      :class="{ desktop_view: $vuetify.breakpoint.smAndUp }"
    >
      <att
        :base64="this.get_document_value('base64')"
        :ext="this.get_document_value('extension')"
        :file_name="this.get_document_value('file_name')"
      />
    </v-container>
    <v-container
      v-else
      fluid
      :class="{ desktop_view: $vuetify.breakpoint.smAndUp }"
    >
      <v-row dense>
        <v-img
          alt="Mäklarföretagets logotyp"
          :src="get_logo()"
          :max-height="header_icon_height"
          contain
          position="center top"
        >
        </v-img>
      </v-row>
      <!--    STREET ADDRESS Erik Olsson -->
      <v-row style="justify-content: center" v-if="this.org_key == '2282'">
        <div style="color: #000000">
          <b>{{ get_value('boostad_get_code.estate', 'streetAddress') }}</b>
        </div>
      </v-row>
      <v-row style="padding-top: 10px">
        <v-img
          alt="Bild på objektet"
          :aspect-ratio="16 / 9"
          width="100%"
          :src="get_value('boostad_get_code.estate', 'url_img')"
        >
          <!--    STREET ADDRESS -->
          <div v-if="this.org_key != '2282'" id="parent">
            <div style="text-align: center" id="child">
              <p style="color: black">
                {{ street_address }}
              </p>
            </div>
          </div>
        </v-img>
      </v-row>
      <br />

      <v-row no-gutters style="font-size: 1.2em" justify="center">
        Tack för att du kom på visningen.
      </v-row>
      <v-row no-gutters style="font-size: 1.2em" justify="center">
        Hur vill du gå vidare?
      </v-row>
      <v-row>
        <br />
        <v-divider
          v-if="this.org_key != '2282'"
          style="padding-top: 10px"
        ></v-divider>
      </v-row>

      <div v-if="loading">
        <v-skeleton-loader
          type="list-item, list-item, list-item"
        ></v-skeleton-loader>

        <br />
      </div>

      <div v-if="show_message">
        <div
          v-for="question in message.A.message.questions[0].response
            .selectable_answers"
          :key="question.value"
        >
          <v-row no-gutters align="center" justify="center">
            <v-btn
              :class="btn_class(question)"
              style="width: 90%; margin-top: 10px"
              @click="btn_click(question)"
            >
              {{ question.text }}
            </v-btn>
          </v-row>
        </div>
        <!-- ALLOW COMMENT -->
        <v-row
          v-if="answer_selected"
          no-gutters
          align="center"
          justify="center"
        >
          <div
            v-if="message.A.message.questions[0].response.allow_comment == '1'"
            class="comment-aria"
          >
            <v-textarea
              v-model="comment_txt"
              label="Kommentar"
              :color="get_back_color()"
              placeholder="Skriv din kommentar"
              auto-grow
              outlined
              rows="1"
              row-height="15"
              :disabled="get_comment_disabled()"
            >
            </v-textarea>
          </div>
        </v-row>
      </div>
      <!-- --------------------------------
      SEND BUTTON
      -------------------------------- -->

      <v-row
        v-if="show_button == true && answer_selected"
        align="center"
        justify="center"
        style="padding-top: 10px"
      >
        <!-- THX FOR ANSWER -->
        <v-btn
          v-if="message_answered == true"
          width="88%"
          outlined
          elevation="0"
          :class="get_class()"
          tile
        >
          {{ getLang('v3_msg', 'lbl_thx_reply') }}
        </v-btn>
        <!-- EXPIRED -->
        <v-btn
          v-else-if="message_expired == true"
          width="88%"
          outlined
          elevation="0"
          :class="get_class()"
          tile
        >
          {{ getLang('v3_msg', 'lbl_msg_expired') }}
        </v-btn>
        <!-- THE QUESTION NOT ANSWERED -->
        <v-btn
          v-else-if="one_question_not_answered == true"
          width="88%"
          readonly
          elevation="0"
          :class="get_class()"
        >
          {{ getLang('v3_b_wizard', 'btn_send') }}
        </v-btn>
        <!-- ALL QUESTIONS NOT ANSWERED -->
        <v-btn
          v-else-if="all_questions_answered == false"
          width="88%"
          readonly
          elevation="0"
          :class="get_class()"
        >
          {{ getLang('v3_b_wizard', 'btn_send') }}
        </v-btn>
        <!-- CLICKED ON SEND BUTTON -->
        <v-btn
          v-else-if="isSending == true"
          width="88%"
          readonly
          elevation="0"
          :class="get_class()"
        >
          <v-progress-circular
            :color="get_color_progress()"
            v-show="isSending"
            indeterminate
          />
        </v-btn>
        <!-- READY TO SEND ANSWER -->
        <v-btn
          :class="get_class()"
          v-else-if="all_questions_answered == true"
          width="88%"
          elevation="0"
          @click="sending_answer()"
        >
          {{ getLang('v3_b_wizard', 'btn_send') }}
        </v-btn>
      </v-row>
      <v-row> </v-row>
      <v-row style="margin-top: 20px">
        <br />
        <v-divider style="margin-top: 20px"></v-divider
      ></v-row>

      <!-- om  objektsbeskrivning finns-->
      <template v-if="obj_url_exists">
        <v-row align="center" justify="center">
          <label class="obLink" @click="get_object(sameorigin)">
            LÄS OBJEKTSBESKRIVNINGEN</label
          >
          <!--           <v-btn text color="primary" @click="get_object(sameorigin)">
            LÄS OBJEKTSBESKRIVNINGEN
          </v-btn> -->
        </v-row>
        <v-row>
          <v-divider style="margin-top: 20px"></v-divider>
        </v-row>
      </template>

      <v-row dense align="center" justify="center">
        <div style="max-width: 95%">
          <!-- ADS-->
          <!-- SÖDERBERG & PARTNERS -->

          <soderberg v-if="ad_soderberg" :adType="adType" />
          <soderberg_split v-else-if="ad_soderberg_split" :adType="adType" />

          <!-- Endast värdering -->

          <valuation v-else-if="ad_valuation" :adType="adType" />
        </div>
      </v-row>
      <v-row>
        <v-divider></v-divider>
        <br />
      </v-row>

      <!----            VISITKORT -------->

      <div>
        <!-- annons för notars guldhemmet GÖR OM KOLLA CSS IST FÖR ORG_KEY-->
        <v-row v-if="this.org_key == '2283'" justify="center" dense>
          <v-img class="advert"> </v-img>
        </v-row>
        <v-row dense align="center" justify="center">
          <v-container fluid>
            <v-card :color="cardColor">
              <v-row no-gutters>
                <v-col cols="5" xl="4" lg="4" md="4" sm="5">
                  <v-img
                    alt="Mäklarens profilbild"
                    :src="get_value('boostad_get_code.broker', 'url_img')"
                    height="100%"
                  /><v-img />
                </v-col>

                <v-col cols="7" xl="8" lg="8" md="8" sm="7">
                  <!--  <v-img
                    style="max-height: 200px"
                    src="/img/infoping_watermark.png"
                    -
                  >-->
                  <v-img class="cardImage">
                    <div style="padding: 10%; line-height: 1">
                      <b>
                        <label
                          :class="[darkSMS ? 'vCardTextDark' : 'vCardText']"
                          style="font-size: 1.2em"
                        >
                          {{ get_value('boostad_get_code.broker', 'userName') }}
                        </label>
                      </b>
                      <br />
                      <label
                        :class="[darkSMS ? 'vCardTextDark' : 'vCardText']"
                        style="font-size: 0.9em"
                      >
                        {{ get_value('boostad_get_code.broker', 'title') }}
                      </label>
                      <br />
                      <br />
                      <label style="font-size: 0.7em">
                        <a
                          :class="[darkSMS ? 'linkDarkMode' : 'linkLightMode']"
                          :href="'tel:' + this.broker_ph"
                          >{{
                            get_value('boostad_get_code.broker', 'cellPhone')
                          }}
                        </a>
                        <br />
                      </label>
                      <label style="font-size: 0.7em">
                        <a
                          :class="[darkSMS ? 'linkDarkMode' : 'linkLightMode']"
                          :href="
                            'mailto:' +
                            this.get_value(
                              'boostad_get_code.broker',
                              'emailAddress'
                            )
                          "
                          >{{
                            get_value('boostad_get_code.broker', 'emailAddress')
                          }}</a
                        >
                      </label>
                    </div>
                  </v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-row>
      </div>
    </v-container>
    <div v-if="showScroll">
      <section
        class="fixed"
        @click="
          $vuetify.goTo(pageHeight);
          showScroll = false;
        "
      >
        <a class="arrow-wrap">
          <span class="arrow"></span>
        </a>
      </section>
    </div>
  </div>
</template>

<script>
import Mixin from '@/Mixin.js';
import get from 'lodash/get';
import rest2Api from '@/api/rest2Api.js';
import soderberg from '@/components/ads/soderberg.vue';
import att from '@/pages/attachmentView.vue';
import valuation from '@/components/ads/valuation.vue';
import soderberg_split from '@/components/ads/soderberg_split.vue';

export default {
  name: 'Reg',
  mixins: [Mixin],
  components: { soderberg_split, valuation, soderberg, att },

  data() {
    return {
      obj_url_exists: false,
      sameorigin: false,
      px_w: null,
      px_h: null,
      square_icon: false,
      rectangle_icon: false,
      header_icon_height: '70',
      street_address: null,
      loading: true,
      attributes: null,
      message: null,
      message_upd: null,
      show_message: false,
      answer_selected: false,
      show_button: true,
      message_answered: false,
      message_expired: false,
      one_question_not_answered: false,
      all_questions_answered: false,
      isSending: false,
      comment_txt: '',

      custom_org_key: null,
      org_key: '',
      broker_ph: null,
      ad_valuation: false,
      ad_soderberg: false,
      ad_soderberg_split: false,

      adType: 'after_viewing',
      showScroll: true,
    };
  },
  watch: {
    message() {
      var answer_enabled = get(this.message, 'A.message.answered_enabled', '');
      var expired = get(this.message, 'A.message.expired', '');
      var selected_answers = get(
        this.message,
        'A.message.questions[0].my_answer.selected_answers',
        []
      );

      if (answer_enabled == '1') this.message_answered = false;
      if (expired == '1') this.message_expired = true;

      if (selected_answers.length > 0) {
        this.message_answered = true;
        this.all_questions_answered = true;
        this.one_question_not_answered = false;
      }
    },
    message_upd() {
      var selected_answers = get(
        this.message_upd,
        'Q.answers[0].selected_answers',
        []
      );

      if (selected_answers.length > 0) {
        this.all_questions_answered = true;
        this.one_question_not_answered = false;
      } else {
        this.all_questions_answered = false;
        this.one_question_not_answered = true;
      }
    },
  },
  beforeDestoy() {},
  updated() {},
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {},
  beforeMount() {
    //OBJEKTSBESKRIVNING UTAN IFRAME
    result = this.get_value('boostad_get_code.branding', 'X-Frame-Options');
    if (result == 'sameorigin') this.sameorigin = true;
    var obj_url = this.get_doc_url('object');
    if (obj_url && obj_url != null && obj_url.length > 0)
      this.obj_url_exists = true;

    this.px_w = window.innerWidth;
    this.px_h = window.innerHeight;

    // MESSAGE
    var obj = new Object();
    obj.function = 'msg_feed_v4';
    var question = new Object();
    var Q = new Object();
    Q.session = this.get_value('boostad_get_code.code_attributes', 'session');

    Q.message_key = this.get_value(
      'boostad_get_code.code_attributes',
      'message_key'
    );
    question.Q = Q;
    obj.question = question;
    rest2Api.ip_function(obj).then((ans) => {
      if (ans.A.status == '1') {
        this.message = ans;
        this.show_message = true;
        this.loading = false;

        // SAVE ANSWER IN STORE
        var o = new Object();
        o.name = 'msg_feed_v4';
        o.data = ans;
        this.$store.dispatch('tellus/commit_answer', o);
        this.init_msg_upd_v4(ans);
      }
    });

    // HEADER ICON
    var result = this.get_value('boostad_get_code.branding', 'header_icon');

    if (result == 'square') this.square_icon = true;
    else if (result == 'rectangle') this.rectangle_icon = true;
    else {
      this.square_icon = false;
      this.rectangle_icon = false;
    }

    //HEADER ICON HEIGHT
    var header_icon_height = this.get_value(
      'boostad_get_code.branding',
      'header_icon_height'
    );

    if (!isNaN(parseFloat(header_icon_height)) && isFinite(header_icon_height))
      this.header_icon_height = header_icon_height;
    else this.header_icon_height = '70';

    this.street_address = this.get_value(
      'boostad_get_code.estate',
      'streetAddress'
    );

    this.broker_ph = this.get_value('boostad_get_code.broker', 'cellPhone');

    this.org_key = this.get_value(
      'boostad_get_code.code_attributes',
      'org_key'
    );

    // ---------------------------------------------
    // ADS
    // ---------------------------------------------
    result = this.get_items('boostad_get_code.ads');

    // ONE AD
    if (result.length == 1) {
      var ad = result[0].name;

      if (ad == 'soderberg') this.ad_soderberg = true;
      else if (ad == 'valuation') this.ad_valuation = true;
    }
    // TWO ADS
    else if (result.length == 2) {
      var ad1 = result[0].name;
      var ad2 = result[1].name;

      if (ad1 == 'soderberg' || ad2 == 'soderberg')
        this.ad_soderberg_split = true;
    }
  },
  computed: {
    pageHeight() {
      return document.body.scrollHeight;
    },

    cardColor() {
      if (this.darkSMS) return 'var(--secondary-bg-color)';
      else return 'var(--vCard-color)';
    },
  },
  methods: {
    handleScroll() {
      this.showScroll = false;
    },

    init_msg_upd_v4(ans) {
      const data = new Object();
      const Q = new Object();

      Q.session = this.get_value('boostad_get_code.code_attributes', 'session');
      Q.message_key = get(ans, 'A.message.message_key', '');
      if (Q.message_key != '') {
        const answers = new Array();
        ans.A.message.questions.forEach((question) => {
          var answer = new Object();
          answer.id = question.id;
          answer.comment = '';

          this.comment_txt = get(question, 'my_answer.comment.body', '');

          var attachments_v3 = new Array();
          answer.attachments_v3 = attachments_v3;

          //INFORMATION - COMES ANSWERED
          if (question.my_answer)
            answer.selected_answers = question.my_answer.selected_answers;
          // ELSE EMPTY ANSWER
          else {
            var selected_answers = new Array();
            answer.selected_answers = selected_answers;
          }

          answers.push(answer);
        });

        Q.answers = answers;

        data.Q = Q;

        this.message_upd = data;
        // SAVE EMPTY MSG UPD IN STORE
        var o = new Object();
        o.name = 'msg_upd_v4';
        o.data = data;
        this.$store.dispatch('tellus/commit_answer', o);
      }
    },
    btn_disabled() {
      var answer = true;
      var my_answer = get(
        this.message,
        'A.message.questions[0].my_answer.selected_answers[0]',
        null
      );
      if (my_answer != null) answer = true;

      return answer;
    },

    get_comment_disabled() {
      if (this.message_answered == true) return 'disabled';
    },
    get_class() {
      this.progressBar = true;

      let result = '';

      var btn = '';

      // MEDDELANDET BESVARAT
      if (this.message_answered == true) {
        if (this.darkSMS) btn = 'btn-answered-dark';
        else btn = 'btn-answered';
      }
      // MEDDELANDET EXPIRED
      else if (this.message_expired == true) {
        if (this.darkSMS) btn = 'btn-expired-dark';
        else btn = 'btn-expired';
      }
      // HÅLLER PÅ ATT SVARA
      else {
        // ALLA MEDDELANDEN ÄR BESVARADE
        if (this.all_questions_answered == true) {
          if (this.darkSMS) btn = 'btn-not-answered-dark';
          else btn = 'btn-not-answered';
          window.scrollBy(0, 100);
        }
        // INTE ALLA MEDDELANDEN ÄR BESVARADE
        else {
          if (this.darkSMS) btn = 'btn-expired-dark';
          else btn = 'btn-expired';
        }
      }

      result = btn;

      return result;
    },
    getLang(controller, what) {
      var answer = 'hej';

      if (controller == 'v3_msg' && what == 'lbl_thx_reply')
        answer = 'Tack för ditt svar';
      else if (controller == 'v3_b_wizard' && what == 'btn_send')
        answer = 'SKICKA SVAR';
      else if (controller == 'v3_msg' && what == 'lbl_msg_expired')
        answer = 'Meddelandet har gått ut';

      return answer;
    },

    btn_class(question) {
      var answer = '';

      if (this.darkSMS) answer = 'btn-not-answered-dark';
      else answer = 'btn-not-answered';

      var my_answer = null;

      if (this.all_questions_answered && !this.message_answered) {
        my_answer = get(
          this.message_upd,
          'Q.answers[0].selected_answers[0]',
          null
        );
      } else {
        my_answer = get(
          this.message,
          'A.message.questions[0].my_answer.selected_answers[0]',
          null
        );
      }

      if (my_answer == null) {
        if (this.darkSMS) answer = 'btn-not-answered-dark';
        else answer = 'btn-not-answered';
      } else if (question.value == my_answer.value) {
        if (this.darkSMS) answer = 'btn-answered-dark';
        else answer = 'btn-answered';
      } else if (this.darkSMS) answer = 'btn-expired-dark';
      else answer = 'btn-expired';
      return answer;
    },
    btn_click(item) {
      this.answer_selected = true;
      // JAG VILL LÄGGA BUD
      if (item.text.toLowerCase().includes('bud' || item.value == 1548)) {
        this.msg_upd(item);
      }
      // JAG ÄR INTE INTRESSERAD
      else if (item.text.toLowerCase().includes('inte' || item.value == 1549)) {
        this.msg_upd(item);
      }
      // JAG ÄR INTESSERAD
      else {
        this.msg_upd(item);
      }
    },
    msg_upd(item) {
      var clone = JSON.parse(JSON.stringify(this.message_upd));
      clone.Q.answers[0].selected_answers[0] = item;
      this.message_upd = clone;

      //LOG CODE
      var log = new Object();
      log.what = 'click';
      log.value = 'answer';
      log.info = item.text;
      this.$store.dispatch('tellus/logThis', log);
    },
    sending_answer() {
      var obj = new Object();
      obj.function = 'msg_upd_v4';
      obj.question = this.message_upd;

      obj.question.Q.answers[0].comment = this.comment_txt;

      rest2Api.ip_function(obj).then((ans) => {
        if (ans.A.status == '1') {
          this.message = ans;
          this.message_upd = null;
          this.show_message = true;
        }
      });

      //LOG CODE
      var answer = get(
        obj,
        'question.Q.answers[0].selected_answers[0].text',
        ''
      );
      var comment = get(obj, 'question.Q.answers[0].comment', '');
      if (comment != '') answer = answer + '\n' + comment;
      //console.log('log code', answer)

      var log = new Object();
      log.what = 'click';
      log.value = 'send';
      log.info = answer;
      this.$store.dispatch('tellus/logThis', log);
    },
  },
};
</script>

<style scoped>
.obLink {
  padding-top: 10px;
  font-size: 16px;
  cursor: pointer;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.desktop_view {
  width: 600px;
  box-shadow: 4px 4px 4px 4px rgb(184 183 183);
}

.offsetUp {
  margin-top: -45px;
}

.pd-zero {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.document_list {
  margin-top: 20px !important;
}
.pd-80 {
  margin-top: 5 !important;
  margin-bottom: 5 !important;
  margin-left: 5 !important;
  margin-right: 5 !important;
  padding-top: 20px !important;
  padding-bottom: 100px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.img-border {
  border-bottom: 1px solid #323232;
}

.compact-form {
  transform: scale(0.8);
  transform-origin: left;
}

#parent::after {
  content: '';
  width: 100%;
  height: 23px;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#child {
  position: relative;
  z-index: 2;
}

#parent2::after {
  content: '';

  position: absolute;

  z-index: 1;
}

#child2 {
  position: relative;

  /*display: flex;
  justify-content: center;
*/
  z-index: 2;
}

.cardImage {
  background: var(--vCard-image-url);

  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.advert {
  background: var(--extra-image-url);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 85%;
  height: 110px;
}

.color {
  color: var(--v-primary-base);
}

.theme--light.v-divider {
  border-color: var(--divider-color) !important;
}
.bid-txt {
  color: white;
  font-weight: bold;
}
.bid-amount {
  color: white;
  font-size: 3.2em;
  font-weight: bold;
}
.fixed {
  position: fixed;
  bottom: 20px;
  width: 100%;
  left: -1px;
  transform: scale(0.4);
}

section {
  float: left;
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0 0 5px 0px #333;
}

/* the important styles  opacity: 0.9; */

.arrow-wrap {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: -5em;
  margin-left: -5em;
  background: white;

  width: 10em;
  height: 10em;
  padding: 4em 2em;
  border-radius: 50%;
  font-size: 0.5em;
  display: block;
  box-shadow: 0px 0px 5px 0px #333;
}

.arrow {
  float: left;
  position: relative;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 3em 3em 0 3em;
  border-color: black transparent transparent transparent;
  /* -webkit-transform: rotate(360deg);*/
}

.arrow:after {
  content: '';
  position: absolute;
  top: -3.2em;
  left: -3em;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 3em 3em 0 3em;
  border-color: white transparent transparent transparent;
  /* -webkit-transform: rotate(360deg);*/
}

a.linkDarkMode {
  color: var(--vCard-text-color-dark);
}
a.linkDarkMode:link {
  color: var(--vCard-text-color-dark);
}
a.linkDarkMode:visited {
  text-decoration: none;
  color: var(--vCard-text-color-dark);
}
a.linkDarkMode:hover {
  text-decoration: underline;
  color: var(--vCard-text-color-dark);
}
a.linkDarkMode:active {
  text-decoration: none;
  color: var(--vCard-text-color-dark);
}

a.linkLightMode {
  color: var(--vCard-text-color) !important;
}
a.inkLightMode:link {
  color: var(--vCard-text-color) !important;
}
a.inkLightMode:visited {
  text-decoration: none;
  color: var(--vCard-text-color) !important;
}
a.inkLightMode:hover {
  text-decoration: underline;
  color: var(--vCard-text-color) !important;
}
a.inkLightMode:active {
  text-decoration: none;
  color: var(--vCard-text-color) !important;
}
</style>
