import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
      source_id: "",
      source_name: "",
      boostad_get_code: null,
      base64: null,
      show_attachment:false,
      show_documents:false,
      view_document:null,
             //simple Alert dialog
             alertData: {
              show: false,
              headerText: 'Alert',
              bodyText: 'This is a simple alert',
              showCancelButton: true,
              confirmAction: () => {},
              cancelAction: () => {},
            },
            lang_filter: null,   
    },
    actions,
    getters,
    mutations,
  };