<template>
  <div>
    <br />

    <v-container v-if="test1" style="background: #42307c" fluid>
      <v-row>
        <v-col cols="12">
          <v-row no-gutters justify="center">
            <label class="lkFont"
              >ANSÖK OM LÅNELÖFTE - SÄKRA UPP DITT DRÖMBOENDE</label
            >
          </v-row>
          <v-row justify="center">
            <!-- <v-btn small style="width: 250px" dark color="#43337b"
              >-->
            <v-btn small style="width: 250px" color="#f4a963">
              <v-img
                max-width="20"
                style="margin-right: 10px"
                src="/img/bankid-black.png"
              >
              </v-img>
              ANSÖK DIREKT</v-btn
            >
          </v-row>
          <v-row justify="center">
            <!-- <v-btn small style="width: 250px" dark color="#43337b"
              >-->
            <v-btn small style="width: 250px; margin-top: 10px" color="#f4a963"
              >KONTAKTA MIG</v-btn
            >
          </v-row>
          <v-row justify="center">
            <label
              style="
                font-size: 9px;
                margin-top: 20px;
                margin-bottom: 20px;
                width: 250px;
                color: white;
              "
              >Genom att klicka på "kontakta mig" godkänner jag att mitt namn
              och telefonnummer skickas till Lånekoll</label
            >
          </v-row>

          <!--           <v-row justify="center">
            <label style="font-size: 9px"
              ><a href="https://www.lanekoll.se/bolan/lanelofte"
                >Läs mer om lånelöfte hos Lånekoll</a
              ></label
            >
          </v-row> -->
        </v-col>
      </v-row>
      <!--       <v-row>
        <v-col cols="12">
          <v-img max-width="150" src="/img/lanekoll.jpeg"> </v-img>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <v-row no-gutters justify="center">
            <label class="lkFont"
              >ANSÖK OM LÅNELÖFTE - SÄKRA UPP DITT DRÖMBOENDE</label
            >
          </v-row>
          <v-row justify="center">
            <v-btn small style="width: 250px" dark color="#43337b">
              <v-img
                max-width="20"
                style="margin-right: 10px"
                src="/img/bankid-white.png"
              >
              </v-img>
              ANSÖK DIREKT</v-btn
            >
          </v-row>
          <v-row justify="center">
            <v-btn
              small
              style="width: 250px; margin-top: 10px"
              dark
              color="#43337b"
            >
              KONTAKTA MIG</v-btn
            >
          </v-row>
          <v-row justify="center">
            <label
              color="white"
              style="font-size: 9px; margin-top: 20px; width: 250px"
              >Genom att klicka på "kontakta mig" godkänner jag att mitt namn
              och telefonnummer skickas till Lånekoll</label
            >
          </v-row>
          <!--           <v-row justify="center">
            <label style="font-size: 9px"
              ><a href="https://www.lanekoll.se/bolan/lanelofte"
                >Läs mer om lånelöfte hos Lånekoll</a
              ></label
            >
          </v-row> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-img max-width="150" src="/img/lanekoll.jpeg"> </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'lanekoll',
  props: {},
  data() {
    return {
      test1: true,
    };
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
/*
.centerContent {
  text-align: center !important;
  align-items: center !important;
}
42307C
'Mochiy Pop P One';*/
.lkFont {
  font-family: 'Bangers';
  font-size: 20px;
  color: #f4a963;
  text-align: center !important;
}

/* a {
  color: #43337b;
} */
</style>
