<template>
  <!--class="text-center"-->
  <div>
    <v-container style="padding-left: 20px; padding-right: 20px">
      <v-row>
        <v-col cols="12">
          <v-row no-gutters justify="center">
            <v-img @click="openRegformSoderberg()" max-width="70%" :src="logo">
            </v-img>
          </v-row>
          <!-- justify="center"         style="
                font-size: 14px;
                margin-top: 10px;

                margin-bottom: 10px;
              "-->
          <v-row justify="center">
            <label @click="openRegformSoderberg()">
              VILL DU HA HJÄLP MED LÅNELÖFTE?</label
            >
          </v-row>
          <v-row>
            <label class="text_box" @click="openRegformSoderberg()"
              >Söderberg & Partners Bolån är en bolåneförmedlare som kombinerar
              storbankernas kvalitet och trygghet med utmanarnas innovation och
              hunger.
              <br />
            </label>
            <v-btn
              small
              style="width: 100%"
              color="primary"
              :class="{ 'white--text': darkSMS }"
              outlined
              @click="openRegformSoderberg()"
              >Läs mer</v-btn
            >
          </v-row>

          <!-- <v-row justify="center">
            <v-btn
              outlined
              small
              style="width: 250px; margin-top: 10px"
              dark
              color="#049cdc"
              @click="openRegform()"
              >LÄS MER</v-btn
            >
          </v-row> -->

          <v-dialog v-model="regform_soderberg">
            <regformSoderberg
              scrollable
              :header="regHeader"
              :subHeader="regSubHeader"
              :logo="regLogo"
              :confirmContact="regConfirmContact"
              :terms="regTerms"
              :readMore="regReadMore"
              @closeMe="
                () => {
                  closeRegform();
                }
              "
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Mixin from '@/Mixin.js';
import regformSoderberg from '@/components/Dialogs/regformSoderberg.vue';

export default {
  name: 'soderberg',
  mixins: [Mixin],
  props: {
    adType: String,
  },
  data() {
    return {
      regform_soderberg: false,
      regHeader: 'Vill du ha hjälp med lånelöfte?',
      regSubHeader:
        'Söderberg & Partners Bolån erbjuder personlig service och hjälp med allt från lånelöfte till köp av bostad och därefter genom hela ditt bostadsägande.',

      regLogo: this.logo,
      regConfirmContact: 'Söderberg & Partners kommer att kontakta dig',
      regTerms :this.get_ad_value('soderberg','terms'),
      regReadMore :this.get_ad_value('soderberg','read_more'),
    };
  },
  watch: {},
  computed: {
    logo() {
      if (this.darkSMS) return 'img/SP_bolan_vit.png';
      else return 'img/sp_bolan.png';
    },
  },
  methods: {
    openRegformSoderberg() {
      this.regHeader = 'Vill du ha hjälp med lånelöfte?';
      this.regSubHeader =
        //  'Fyll i formuläret så ringer Söderbeg & Partners Bolån upp dig för vidare dialog';
        'Söderberg & Partners Bolån erbjuder personlig service och hjälp med allt från lånelöfte till köp av bostad och därefter genom hela ditt bostadsägande.';

      this.regLogo = this.logo;
      this.regConfirmContact = 'Söderberg & Partners kommer att kontakta dig';
      this.regTerms =this.get_ad_value('soderberg','terms'),
      this.regReadMore =this.get_ad_value('soderberg','read_more'),
      //posta till loggen
      this.$store.dispatch('tellus/logThis', {value:'soderberg_ad_' + this.adType});
      this.regform_soderberg = true;
    },
    closeRegform() {
      this.regform_soderberg = false;
    },
  },
  components: {
    regformSoderberg,
  },
};
</script>

<style scoped>
/*
.centerContent {
  text-align: center !important;
  align-items: center !important;
}
'Mochiy Pop P One';*/
.lkFont {
  font-family: 'Bangers';
  font-size: 20px;
  color: #049cdc;
  text-align: center !important;
}
.text_box {
  font-size: 12px;
  margin-top: 10px;

  margin-bottom: 10px;
  text-align: justify;
  word-break: keep-all;
}

/* a {
  color: #43337b;
} */
</style>
