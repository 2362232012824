<template>
  <div class="text-center">
    <br />
    <v-container>
      <v-row align="center">
        <v-col cols="6">
          <v-img
            @click="openRegformSoderberg()"
            alt="Bolåneföretagets logotyp"
            :src="logo"
            contain
          >
          </v-img>
        </v-col>
        <v-divider vertical> </v-divider>
        <v-col cols="6">
          <v-img
            alt="Mäklarföretagets logotyp"
            :src="get_logo()"
            style="max-height: 100px"
            @click="openBrokerAd()"
            contain
          >
          </v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <label @click="openRegformSoderberg()" style="font-size: 14px"
            >{{ getLang('v6_customer', 'lbl_loan') }}?</label
          >
        </v-col>
        <v-divider vertical> </v-divider>
        <v-col cols="6">
          <label @click="openBrokerAd()" style="font-size: 14px"
            >{{ getLang('v6_customer', 'lbl_valuation') }}?</label
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn
            small
            style="width: 100%"
            color="primary"
            outlined
            @click="openRegformSoderberg()"
            >{{ getLang('v6_customer', 'lbl_readmore') }}</v-btn
          >
        </v-col>
        <v-divider vertical> </v-divider>
        <!-- om regform lånelöfte   @click="openRegform('broker')" -->
        <v-col cols="6">
          <v-btn
            small
            style="width: 100%"
            color="primary"
            outlined
            @click="openBrokerAd()"
            >{{ getLang('v6_customer', 'lbl_readmore') }}</v-btn
          >
        </v-col>
      </v-row>
      <!--scrollable-->
      <!-- söderberg-->
      <v-dialog v-model="regform_soderberg" v-if="regform_soderberg">
        <regformSoderberg
          :header="regHeader"
          :subHeader="regSubHeader"
          :logo="regLogo"
          :confirmContact="regConfirmContact"
          :terms="regTerms"
          :readMore="regReadMore"
          @closeMe="
            () => {
              closeRegform();
            }
          "
        />
      </v-dialog>

      <!-- broker-->
      <v-dialog v-model="regform_broker" v-if="regform_broker">
        <regformBroker
          :header="regHeader"
          :subHeader="regSubHeader"
          :logo="regLogo"
          :confirmContact="regConfirmContact"
          :terms="regTerms"
          :readMore="regReadMore"
          @closeMe="
            () => {
              closeRegform();
            }
          "
        />
      </v-dialog>

      <!-- fri värdering --->
      <v-dialog width="100%" height="100%" v-model="goToWeb">
        <v-card>
          <div class="text-right">
            <v-btn
              class="sticky"
              style="margin-left: 5px; transform: scale(0.8)"
              icon
              @click="goToWeb = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <div class="iframe-container">
            <iframe
              :src="this.get_ad_value('valuation','read_more')"
              allowfullscreen
            >
            </iframe>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import Mixin from '@/Mixin.js';
import regformSoderberg from '@/components/Dialogs/regformSoderberg.vue';
import regformBroker from '@/components/Dialogs/regformBroker.vue';
//import rest2Api from '@/api/rest2Api.js';
//
export default {
  name: 'soderberg_split',
  mixins: [Mixin],
  props: {
    adType: String,
  },

  data() {
    return {
      regform_soderberg: false,
      regform_broker: false,
      regHeader: '',
      regSubHeader: '',
      regLogo: '',
      regConfirmContact: '',
      regTerms: '',
      regReadMore: '',
      goToWeb: false,
    };
  },
  watch: {},
  mounted() {},
  computed: {
    logo() {

      if (this.darkSMS) return 'https://infoping.net/img/logo/SP_bolan_vit.png';
      else return 'https://infoping.net/img/logo/sp_bolan.png';
    },
  },

  methods: {
    openRegformSoderberg() {
      this.regHeader = 'Vill du ha hjälp med lånelöfte?';
      this.regSubHeader =
        //  'Fyll i formuläret så ringer Söderbeg & Partners Bolån upp dig för vidare dialog';
        'Söderberg & Partners Bolån erbjuder personlig service och hjälp med allt från lånelöfte till köp av bostad och därefter genom hela ditt bostadsägande.';

      this.regLogo = this.logo;
      this.regConfirmContact = 'Söderberg & Partners kommer att kontakta dig';
      this.regTerms = this.get_ad_value('soderberg','terms');
      this.regReadMore = this.get_ad_value('soderberg','read_more');

      //posta till loggen 
      this.$store.dispatch('tellus/logThis', {value:'soderberg_ad_' + this.adType});
      this.regform_soderberg = true;
    },
    openRegformBroker() {
      this.regHeader = 'Boka kostnadsfri värdering!';
      this.regSubHeader = 'Fyll i uppgifterna nedan så kontaktar vi dig';
      // 'Går du i försäljningstankar och behöver veta vad din bostad är värd? En värdering är helt kostnadsfri hos oss och kräver ingen motprestation från din sida.';
      this.regLogo = this.get_logo();
      this.regConfirmContact = 'Vi kommer att kontakta dig';

      this.regTerms = this.get_ad_value('valuation','terms');
      this.regReadMore = this.get_ad_value('valuation','read_more');

      // this.adType = 'valuation';
      //posta till loggen
      this.$store.dispatch('tellus/logThis', {value:'broker_ad_' + this.adType});
      this.regform_broker = true;
    },

    openBrokerAd() {
      if (this.get_ad_value('valuation','lead_type') == 'regform') {
        this.openRegformBroker();
      } else {
        //posta till loggen
      this.$store.dispatch('tellus/logThis', {value:'broker_ad_' + this.adType});
        this.goToWeb = true;
      }
    },
    closeRegform() {
      this.regform_soderberg = false;
      this.regform_broker = false;
    },
  },
  components: {
    regformSoderberg,
    regformBroker,
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 0;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 91vh;
  left: 0;
  position: relative;
  width: 100%;
  top: 0;
}
</style>
