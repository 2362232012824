<template>
  <!-- TEST2 -->
  <div >
      <v-container >

        
      <pdf
        :src="src"
        :page="page"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
        :style="get_style()"
        @progress="loadedRatio = $event"
        @loaded="loaded = $event"
      ></pdf>
      
          </v-container>
          <v-container >
          <!-- <v-footer  > -->
            
      <v-row>
        <v-col cols="4" class="text-left"  @click="prev(currentPage)">
          <v-btn icon>
          <v-icon left>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="4" class="text-center">
          {{ currentPage }} / {{ pageCount }}
         
        </v-col>
        
        <v-col cols="4" class="text-right" @click="next(currentPage, pageCount)">
          <v-btn icon >
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>

      </v-row>
      <!-- </v-footer> -->
          </v-container>
    </div>

</template>

<script>
 
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
  },
  props: { base64: String, ext: String ,file_name:String},
  data() {
    return {
      src: null,
      page: 1,
      numPages: undefined,
      currentPage: 0,
      pageCount: 0,
      error: 0,
      loading: true,
      loadedRatio: 0,
      loaded: 0,
      show_loading: true,
      start_load: null,
      phone_number: '',
      message_key: '',
      model: -1,
      window_h:this.$vuetify.breakpoint.height,
    };
  },
  watch: {
    model(value) {
      this.page_view(value + 1);
    },
    
  },
  
    beforeMount() {
        this.loading=false
        this.src='data:application/pdf;base64,'+this.base64
    },
    prev(currentPage) {
      if (currentPage > 1) this.page--;
    },
    methods: {
        next(currentPage,numPages){
            if(currentPage<numPages)
                this.page++;
        },
        prev(currentPage){
            if(currentPage>1)
                this.page--;
        },get_style(){
          let answer="display: inline-block; width:95%";
          if(this.$vuetify.breakpoint.width>770)
          answer="display: inline-block; width:100%";
          else   if(this.$vuetify.breakpoint.width>500)
          answer="display: inline-block; width:80%";

          return answer

        },
    }

}
 
</script>
