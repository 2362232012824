import {
    BASE64,
    SHOW_ATTACHMENT,
    SHOW_DOCUMENTS,
    VIEW_DOCUMENT,
    SHOW_ALERT,
    SET_ANSWER,
    LANG_FILTER,
    BOOSTAD_GET_CODE,
    SOURCE_ID,
    SOURCE_NAME,

  } from './types.js';

  export default {  
    //----------------------------------------------
    // BOSTAD SMS
    //----------------------------------------------
    [SOURCE_ID](state, payload) {
      state.source_id = payload;
    }, 
    [SOURCE_NAME](state, payload) {
      state.source_name = payload;
    },
    [BOOSTAD_GET_CODE](state, payload) {
      state.boostad_get_code = payload;
   },
    [BASE64](state, payload) {
       state.base64 = payload;
    },
    
    [SHOW_ATTACHMENT](state, payload) {
       state.show_attachment = payload;
    },
    
    [SHOW_DOCUMENTS](state, payload) {
       state.show_documents = payload;
    },

    [VIEW_DOCUMENT](state, payload) {
      state.view_document = payload;
   },
   [SHOW_ALERT](state,alertData) {
      state.alertData=alertData;
    },
     [SET_ANSWER](state, obj) {
      if(obj.name && obj.name!=null)
      {
        state[obj.name]=obj.data;
      }
    },
    
    [LANG_FILTER](state,payload) {
      state.lang_filter=payload;
    },
    
  };
  