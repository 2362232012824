<template>
  <v-app :style="{ 'font-family': fnt }" :class="customCSS">
    <div>
      <Alert />
      <!-- LOADING -->
      <div v-if="loading" class="text-center load">
        <v-progress-circular
          class="mid"
          :size="50"
          color="primary"
          align="center"
          indeterminate
        ></v-progress-circular>
      </div>

      <div v-if="show_viewing_interest">
        <ViewingInterest />
      </div>

      <div v-else-if="show_reg">
        <Reg />
      </div>
    </div>
  </v-app>
</template>

<script>
import Reg from './pages/Reg.vue';
import ViewingInterest from './pages/VIEWING/Interest.vue';

import rest2Api from '@/api/rest2Api.js';
import Alert from '@/components/Dialogs/alert.vue';

import Mixin from '@/Mixin.js';

export default {
  name: 'App',
  mixins: [Mixin],
  components: {
    Reg,
    ViewingInterest,
    Alert,
  },

  data() {
    return {
      show_reg: false,
      loading: false,
      fnt: 'semplicita_pro',
      show_viewing_interest: false,
    };
  },

  async beforeMount() {
    // STORE GUID
    var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    await this.fetch_source_id(guid);
    //console.log('App','beforeMount',2,this.is_item_loaded)
    await this.fetch_source_name('BoostadSms');

    // -----------------------------------------------------------
    // SET LANG
    // -----------------------------------------------------------
    this.lang = (navigator.language || navigator.userLanguage).substr(0, 2);

    // -----------------------------------------------------------
    // GET CODE
    // -----------------------------------------------------------
    var url_split = window.location.pathname.toString().split('/');
    var link_code = url_split[1];

    var obj = new Object();
    obj.code = link_code;
    this.$store.dispatch('tellus/fetch_boostad_get_code', obj).then((res) => {
      if (res) {
        // -----------------------------------------------------------
        // REDIRECT EXISTS
        // -----------------------------------------------------------
        var type_of_sms = this.get_value(
          'boostad_get_code.code_attributes',
          'type_of_sms'
        );

        if (
          type_of_sms &&
          type_of_sms != null &&
          type_of_sms == 'obj_link_redirect'
        ) {
          window.location.assign(this.get_doc_url('object'));
        } else {
          // -----------------------------------------------------------
          // SHOW MESSAGE
          // -----------------------------------------------------------
          // -----------------------------------------------------------
          // GET LANG
          // -----------------------------------------------------------

          this.fetchLang();

          // -----------------------------------------------------------
          // TOGGLE DARK MODE
          // -----------------------------------------------------------
          this.toggleDarkMode();

          // -----------------------------------------------------------
          // SET FONT
          // -----------------------------------------------------------
          var font_family = this.get_value(
            'boostad_get_code.branding',
            'font_family'
          );

          if (font_family != null) this.fnt = font_family;

          // -----------------------------------------------------------
          // SET COLOR
          // -----------------------------------------------------------
          var back_color = this.get_value(
            'boostad_get_code.branding',
            'back_color'
          );
          this.$vuetify.theme.themes.light.accent = back_color;
          this.$vuetify.theme.themes.light.primary = back_color;

          this.$vuetify.theme.themes.dark.primary = '#' + 'dddddd';
          this.$vuetify.theme.themes.dark.accent = back_color;

          let what = this.get_value('boostad_get_code.code_attributes', 'what');

          // -----------------------------------------------------------
          // SMS REG
          // -----------------------------------------------------------
          if (what == 'reg_sms') this.show_reg = true;
          // else if (what == 'viewing_identify')
          //   this.show_viewing_identify = true;
          // -----------------------------------------------------------
          // SMS REG NEW PRODUCTION
          // -----------------------------------------------------------
          else if (what == 'reg_sms_newprod') this.show_reg = true;
          // -----------------------------------------------------------
          // SMS VIEWING
          // -----------------------------------------------------------
          else if (what == 'viewing_sms') this.show_viewing_interest = true;

          this.loading = false;

          //LOG CODE
          var log = new Object();
          log.what = 'click';
          log.value = 'sms_link';
          log.info = what;
          this.$store.dispatch('tellus/logThis', log);
        }
      }
    });
    // }
  },
  computed: {
    customCSS() {
      // -----------------------------------------------------------
      // CHECK ORG for special css
      // -----------------------------------------------------------

      if (
        this.get_value('boostad_get_code.code_attributes', 'org_key') == '2785'
      )
        return 'era';
      else if (
        this.get_value('boostad_get_code.code_attributes', 'org_key') == '2417'
      )
        return 'smh';
      else if (
        this.get_value('boostad_get_code.code_attributes', 'org_key') == '2283'
      )
        return 'notar';
      else if (
        this.get_value('boostad_get_code.code_attributes', 'org_key') == '2285'
      )
        return 'maklarringen';
      else if (
        this.get_value('boostad_get_code.code_attributes', 'org_key') == '2282'
      )
        return 'erikolsson';
      else return '';
    },
  },
  methods: {
    async fetch_source_id(id) {
      await this.$store.dispatch('tellus/fetch_source_id', id);
    },
    async fetch_source_name(name) {
      await this.$store.dispatch('tellus/fetch_source_name', name);
    },
    toggleDarkMode() {
      if (this.darkSMS) {
        this.$vuetify.theme.dark = true;
        var meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = this.themeColor();
        // getComputedStyle(
        //   document.documentElement
        // ).getPropertyValue('-theme-dark'); //'#121212';
        document.getElementsByTagName('head')[0].appendChild(meta);
      }
    },

    themeColor() {
      //funkar bara att hämta från main css...
      if (
        this.get_value('boostad_get_code.code_attributes', 'org_key') == '2785'
      )
        return '#303444';
      else return '#121212';
    },

    async lang_chk(lang) {
      await rest2Api.lang_chk(lang).then((res) => {
        //om språket inte finns returneras en
        this.lang = res;
      });
    },
    async fetchLang() {
      this.lang_chk(this.lang).then(() => {
        this.$store.dispatch('tellus/fetch_lang_filter', {
          lang: this.lang,
          filter: 'all',
        });
      });
    },
  },
};
</script>

<style>
.load {
  height: 100vh;
}
.mid {
  margin-top: 50%;
}
</style>
