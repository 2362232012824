import Vue from 'vue';
import Vuex from 'vuex';
import tellus from './tellus';

Vue.use(Vuex)
import VueScrollTo from 'vue-scrollto';


Vue.use(VueScrollTo);


export default () =>
  new Vuex.Store({
    modules: { tellus},
    strict: process.env.NODE_ENV !== 'production'
  });
