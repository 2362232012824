<template>
  <div style="max-width: 600px">
    <!--   <v-card :class="{ cardBackgroundDark: darkSMS }">-->
    <v-card :color="cardColor">
      <div class="text-right">
        <v-btn
          class="sticky"
          style="margin-left: 5px; transform: scale(0.8)"
          icon
          @click="close"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <div
        class="text-center"
        style="padding-left: 10px; padding-right: 10px; padding-bottom: 10px"
      >
        <label style="font-weight: bold" class="boxTitle">{{ header }}</label>
        <br />
        <label :class="[darkSMS ? 'boxTextDark' : 'boxText']">{{
          subHeader
        }}</label>
        <br />
      </div>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="6" sm="6" md="6">
              <v-text-field v-model="personalData.firstName" label="Förnamn">
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="personalData.lastName"
                label="Efternamn"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="homeAddress"
                label="Din gatuadress"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="personalData.emailAddress"
                label="E-post"
              ></v-text-field>
            </v-col>
            <!--   v-scroll-to="'#element'"-->
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                @click="scrollTo"
                class="compact-form"
                v-model="approve"
              >
                <div slot="label">Jag godkänner att mina uppgifter sparas</div>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-img max-width="50%" contain :src="logo"> </v-img>
        <v-spacer></v-spacer>

        <!--id="element"-->
        <v-btn
          id="scroll-to-this-button"
          color="primary"
          :class="{ 'black--text': darkSMS }"
          x-small
          rounded
          dark
          @click="save()"
          >Kontakta mig</v-btn
        >
      </v-card-actions>

      <v-dialog width="100%" height="100%" v-model="is_url">
        <v-card>
          <div class="text-right">
            <v-btn
              class="sticky"
              style="margin-left: 5px; transform: scale(0.8)"
              icon
              @click="is_url = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <div class="iframe-container">
            <iframe :src="url" allowfullscreen> </iframe>
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import Mixin from '@/Mixin.js';
import get from 'lodash/get';
import rest2Api from '@/api/rest2Api.js';

export default {
  name: 'regform',
  mixins: [Mixin],
  props: {
    header: String,
    subHeader: String,
    logo: String,
    confirmContact: String,
    terms: String,
    readMore: String,
  },
  data() {
    return {
      personalData: '',
      layout: '',
      approve: false,
      is_url: false,
      file_name: '',
      url: '',
      homeAddress: '',
    };
  },
  beforeMount() {
    this.loadPersonalData();
  },

  components: {},
  computed: {
    cardColor() {
      if (this.darkSMS) return 'var(--main-bg-color)';
      else return '#ffffff';
    },
  },
  methods: {
    link(val) {
      this.is_url = true;
      this.url = val;
    },
    loadPersonalData() {
      var arr = new Object();
      var attributes = this.get_items('boostad_get_code.code_attributes');
      attributes.forEach((element) => {
        arr[element.name] = element.value;
      });
      this.personalData = arr;
    },
    // && this.approve == false
    save() {
      if (this.homeAddress == '' && this.approve == false) {
        this.alertBox('', 'Du måste fylla i gatuadress och godkänna villkoren');
      } else if (this.approve == false) {
        this.alertBox('', 'Du måste godkänna villkoren');
      } else if (this.homeAddress == '') {
        this.alertBox('', 'Du måste fylla i vilken adress värderingen gäller');
      } else {
        var clone = this.clone_obj(this.personalData);
        var code_id = get(this.$store.state.tellus, 'boostad_get_code.id', 0);
        if (this.str2int(code_id.toString()) > 0) clone.code_id = code_id;

        var obj = new Object();
        obj.end_point = 'boostad/vitec/lead';
        obj.data = clone;
        obj.data.homeAddress = this.homeAddress;

        rest2Api.ip_post(obj);

        this.alertBox('Tack!', this.confirmContact);
        this.close();
      }
    },

    close() {
      //triggar eventet i sidan där komponenten läses in

      this.$emit('closeMe');
    },

    ValidateEmail() {
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          this.personalData.emailAddress
        )
      ) {
        return true;
      }

      return false;
    },

    scrollTo() {
      document.getElementById('scroll-to-this-button').scrollIntoView();
    },
  },
};
</script>

<style scoped>
a:link {
  color: var(--v-primary-base) !important;

  text-decoration: none;
}
.theme--light.a-link {
  border-color: var(--v-primary-base) !important;
}

.compact-form {
  transform: scale(0.8);
  transform-origin: left;
}

.iframe-container {
  overflow: hidden;
  padding-top: 0;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 91vh;
  left: 0;
  position: relative;
  width: 100%;
  top: 0;
}
</style>
