
export const BASE64 = 'BASE64';
export const SHOW_ATTACHMENT = 'SHOW_ATTACHMENT';
export const SHOW_DOCUMENTS = 'SHOW_DOCUMENTS';
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT';
export const SHOW_ALERT='SHOW_ALERT';
export const SET_ANSWER='SET_ANSWER';

export const LANG_FILTER='LANG_FILTER';
export const BOOSTAD_GET_CODE='BOOSTAD_GET_CODE';

export const SOURCE_ID = 'SOURCE_ID';
export const SOURCE_NAME = 'SOURCE_NAME';