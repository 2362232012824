import get from 'lodash/get';

export default {


get_dark_mode: (state)  =>{

    let result = null;
    var attributes = get(
  state,
      "boostad_get_code.branding",
     null
    );

  
    if (attributes)
    {
  
    var values = attributes.filter((item) => item.name.toLowerCase()==='dark_mode');
  
   
    result = get(values[0], "value", "");
    }

  if (result && result == 1) return true;
  
  else
    return false;
  },
  
  get_dark_logo: (state)  =>{
  
    let result = null;
    var attributes = get(
  state,
  "boostad_get_code.branding",
     null
    );
  
   
  
    if (attributes)
    {
 
    
    var values = attributes.filter((item) => item.name.toLowerCase()==='dark_logo');

    
   
    result = get(values[0], "value", "");
    }
  return result;
  }
};