export default {
    demo_broker_obj (){


       
      var obj = new Object();
      var items=new Array();
      var item= new Object();

     obj.id=0;
     obj.customer_name="Demo";

      // -----------------------------------------------------------
      // CODE ATTRIBUTES
      // -----------------------------------------------------------
      items=new Array();

      item= new Object();
      item.name="what";
      item.value="reg_sms";
      items.push(item);
      
      obj.code_attributes=items;
      // -----------------------------------------------------------
      // BRANDING
      // -----------------------------------------------------------
      items=new Array();
      item= new Object();

      item.name="back_color";
      item.value="#196386";
      items.push(item);

      
      item= new Object();
      item.name="logo";
      item.value="/img/demo_logo.png";
      items.push(item);
      
      obj.branding=items;
      // -----------------------------------------------------------
      // BROKER
      // -----------------------------------------------------------
      items=new Array();

      item= new Object();
      item.name="url_img";
      item.value="/img/rickard.png";
      items.push(item);

      item= new Object();
      item.name="userName";
      item.value="Richard Smith";
      items.push(item);

      
      item= new Object();
      item.name="cellPhone";
      item.value="0702251743";
      items.push(item);

      item= new Object();
      item.name="title";
      item.value="Fastighetsmäklare";
      items.push(item);

      obj.broker=items;
      // -----------------------------------------------------------
      // ESTATE
      // -----------------------------------------------------------
      items=new Array();

      item= new Object();
      item.name="url_img";
      item.value="/img/estate.png";
      items.push(item);

      
      item= new Object();
      item.name="streetAddress";
      item.value="Gården";
      items.push(item);

      obj.estate=items;
       
       
       
       return obj;
      
      
      },
      
};
